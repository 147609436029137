@import '../../styles/customMediaQueries.css';

.unsupportedCountryError {
  color: var(--colorFail);
}

.root {
  /* Parent component should not shrink this field */
  flex-shrink: 0;

  /* This component uses flexbox layout too */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.input {
  /* Parent component should not shrink this field */
  flex-shrink: 0;
  margin-bottom: 24px;
  width: 100%;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.error {
  color: var(--colorFail);
  font-size: 13px !important; /* TODO: Change the <p> tag in front-end to <span> to remove !important */
  margin-top: 7px !important; /* TODO: Change the <p> tag in front-end to <span> to remove !important */
  padding: 0;
  margin-bottom: 0;
}

input.inputError {
  border: var(--borderErrorField);
}

.longForm {
  width: 100%;
  margin-bottom: 24px;

  @media (--viewportSmall) {
    width: calc(50% - 9px);
  }
}
