@import '../../../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.sectionHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 6px;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    align-items: baseline;
    gap: 8px;
    margin-top: 0;
  }
}

.planInfo {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }

  & > p {
    margin-top: 0;
    font-size: 16px;
    line-height: 120%;

    @media (--viewportMedium) {
      width: calc(100% - 20%);
      padding-right: 30px;
    }
  }

  & .editPlanButton {
    margin: 0;
    padding: 10px 16px;
    display: inline-block;
    border: none;
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: var(--transitionStyle);

    &:hover {
      transition: var(--transitionStyle);
      color: var(--colorWhite);
      background-color: var(--marketplaceColorDark);
    }
  }
}

.section {
  margin: 24px 0 0 0;
}

.addExceptionButton {
  margin: 0;
  padding: 10px 16px;
  display: inline-block;
  border: none;
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: var(--transitionStyle);

  &:hover {
    transition: var(--transitionStyle);
    color: var(--colorWhite);
    background-color: var(--marketplaceColorDark);
  }
}

.goToNextTabButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
  }
}

.modalContainer {
  composes: marketplaceModalBaseStyles from global;
  min-height: 100vh;
  height: 100%;

  padding-left: 0;
  padding-right: 0;

  background-color: var(--colorGrey50);
  border-radius: var(--borderRadiusLarge);

  @media (--viewportMedium) {
    flex-basis: 576px;
    min-height: auto;
    height: auto;
  }
}

.error {
  color: var(--colorFail);
}

.actionBtnSec {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 50px;

  & .submitButton {
    flex-shrink: 0;

    @media (--viewportLarge) {
      display: inline-block;
      width: 241px;
    }
  }
}

.customCalendarSec {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 50px;

  & .customCalendar {
    order: 2;

    @media (--viewportMedium) {
      order: 1;
    }
  }

  & .sideBar {
    width: 100%;
    order: 1;
    margin-bottom: 20px;
    background-color: var(--colorWhite);
    border: solid 1px var(--colorGrey100);
    box-shadow: var(--boxShadow);
    border-radius: 10px;
    padding: 12px;
    margin-top: 15px;

    @media (--viewportMedium) {
      order: 2;
    }
    & > h2 {
      font-size: 20px;
      font-weight: var(--fontWeightSemiBold);
      margin: 0 0 20px 0;
      padding: 0;
      line-height: 120%;
    }
    & .sidebartabs {
      background-color: var(--colorGrey50);
      padding: 6px;
      border-radius: 100px;
      & .sidebartab {
        background-color: transparent;
        border: solid 1px transparent;
        color: var(--colorGrey600);
        padding: 6px 10px;
        border-radius: 100px;
        font-size: 14px;
        line-height: 120%;
        display: inline-block;
        width: calc(100% / 2);
        text-align: center;
        cursor: pointer;
        &.activeSelectedTab {
          background-color: var(--colorWhite);
          border-color: var(--marketplaceColor);
          color: var(--marketplaceColor);
        }
      }
    }
    & .sidebarBlock {
      margin-top: 24px;
      & > h3 {
        font-size: 16px;
        color: var(--colorGrey800);
        font-weight: var(--fontWeightSemiBold);
        line-height: 130%;
        margin: 0 0 10px 0;
        padding: 0;
      }
      & .markAvailability {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 12px;
        & .available {
          border: solid 1px var(--colorGrey100);
          padding: 10px 20px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          border-radius: 100px;
          & > svg {
            margin-right: 10px;
            width: 20px;
            height: 20px;
          }
        }
      }
      & .availableDays {
        /* padding: 15px;
        border: solid 1px var(--colorGrey100);
        border-radius: 10px; */

        & .weekendInput {
          margin-top: 10px;
          margin-bottom: 14px;
        }
        & .markAvailability {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          margin-bottom: 12px;
          & > span {
            width: calc(100% - 20px);
          }
          & > svg {
            width: 20px;
            height: 20px;
          }
        }
        & .days {
          display: flex;
          flex-direction: column;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          & > span {
            color: var(--colorGrey300);
            font-size: 15px;
            font-weight: var(--fontWeightMedium);
            line-height: 130%;
            padding: 0;
            margin: 0;
          }
          & > p {
            padding: 0;
            margin: 0;
            font-size: 24px;
            font-weight: var(--fontWeightBold);
            line-height: 130%;
            color: var(--colorGrey800);
          }
        }
      }
    }
    & .sideInfo {
      border-radius: 10px;
      background-color: var(--colorWhite);
      padding: 20px;
      border: solid 1px var(--colorGrey100);

      & > h2 {
        font-size: 20px;
        line-height: 120%;
        font-weight: var(--fontWeightSemiBold);
        margin: 10px 0;
        color: var(--colorBlack);
      }

      & > p {
        font-size: 16px;
        line-height: 120%;
        margin: 0;
        color: var(--colorGrey400);
      }
    }
  }
}

.checkboxes {
  display: flex;
  gap: 22px;
}

.checkbox {
  display: flex;

  & > input {
    cursor: pointer;
    width: fit-content;
    position: relative;
    bottom: 14px;
    left: 10px;
  }
}

.allExceptions {
  & > h4 {
    margin: 0;
  }
}

.exceptionDates {
  display: flex;
  gap: 42px;
}

.remove {
  cursor: pointer;
  position: relative;
  top: 11px;
}

.startDate {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: #484848;
  padding: 11px 11px 9px;
}

.endDate {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: #484848;
  padding: 11px 11px 9px;
}

.weekend {
  margin-top: 28px;

  & > input {
    cursor: pointer;
  }
}

.actionButtons {
  display: flex;
  margin-top: 14px;
  gap: 10px;
}

.activeMonth {
  border: 1px solid #3e5ba6;
}

.activeSelectedTab {
  border: 1px solid #3e5ba6;
}
.pageHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
  }
  @media (--viewportMedium) {
    margin-bottom: 30px;
  }
  & > h2 {
    margin: 0 !important;
    padding: 0;
  }
  & p {
    padding-bottom: 0;
    margin-top: 0;
  }
  & .headTabs {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & .month {
      border-radius: 100px;
      background-color: var(--colorWhite);
      border: solid 1px var(--colorGrey100);
      padding: 4px 15px;
      display: inline-block;
      font-size: 16px;
      font-weight: var(--fontWeightMedium);
      cursor: pointer;
      transition: var(--transitionStyle);
      &:not(:last-child) {
        margin-right: 10px;
      }
      &:hover {
        transition: var(--transitionStyle);
        border-color: var(--marketplaceColor);
        background-color: var(--colorWhite);
        color: var(--marketplaceColor);
      }
      &.activeMonth {
        transition: var(--transitionStyle);
        border-color: var(--marketplaceColor);
        background-color: var(--marketplaceColor);
        color: var(--colorWhite);
      }
    }
  }
}

.availabilityTab {
}

.availabilityExceptionButtons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  & > button {
    font-size: 13px;
  }
}

.pricesByDay {
}

.resetPrices {
  cursor: pointer;
  color: #000000 !important;
  border: none !important;
  text-align: end !important;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 700;
}

.iconSpinner {
  border: none !important;
}

.pricesByDaySelection {
  border: none !important;
}

.pricesByDayOptions {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  & > span {
    cursor: pointer;
    position: relative;
    left: 10px;
  }
}
